<template>
  <div class="head">
    <el-dropdown>
      <i class="el-icon-setting" style="margin-right: 15px"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item ><div @click="logout()">退出</div></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
// import { removeUserId } from '../../utils/store.js'

export default {
  name: 'Head',
  props: {

  },
  computed: {
    // name(){
    //   const name = Cookie.get('user_name')
    //   const reg = /[A-Z]+/g
    //   const res = name.replace(reg, ($2) => { return ' ' + $2 })
    //   return res.split(' ').reverse().join(' ')
    // }
  },
  methods: {
    logout () {
      removeUserId()
      console.log('out')
      Cookie.remove('loginId')
      this.$router.push('/login')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.head{
  height: 50px;
  background: url('../../assets/pg/header.png');
  line-height: 50px;
  text-align: right;
  .el-icon-setting{
    color: #fff;
  }
}
</style>
